<template>
  <div>
    <div id="surveyElement">
      <Survey :Header="Title" :id="ID" :Postid="PostID" />
    </div>
  </div>
</template>

<script>
import Survey from "../components/SurveyJS";

export default {
  name: "SurveyJS",
  components: {
    Survey,
  },
  data() {
    return {
      Title: this.$route.params.title,
      ID: this.$route.params.id,
      PostID: this.$route.params.postid
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>
