<template>
  <v-container >
    <v-row class="Header">
      <v-col cols="12">
        <h1>{{ Header }}</h1>
      </v-col>
    </v-row>
    <v-card class="survey mb-16" elevation="24">
      <survey :survey="survey" />
    </v-card>
  </v-container>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/survey.css";
import "./index.css";
import * as SurveyPDF from "survey-pdf";
Survey.StylesManager.applyTheme("orange");
// Working with vue-js-excel package to see if could download as excel file.
//import { VueJsExcel } from '../functions/vue-js-excel'

export default {
  props: {
    Header: {
      Type: String,
      Default: "This is the default title", // seconds
    },
    id: {
      Type: String,
      Required: true,
    },
    Postid: {
      Type: String,
      Required: true,
    },
  },
  name: "SurveyJS",
  data() {
    let data = [];
    //let columns = ['Person', 'Question 1', 'Question 2', 'Comments'];
    const json = { surveyId: this.id };
    const survey = new Survey.Model(json);

    Survey.surveyStrings.loadingSurvey =
      "Please wait. we are loading the form…";

    // use these to load data back to SurveyJs if required
    survey.surveyPostId = this.Postid;
    //Optionally, show saving progress and show an error and "Save Again" button if the results can't be stored.
    survey.surveyShowDataSaving = true;
    Survey.surveyStrings.savingData =
      "Please wait. We are validating and saving your response.";
    Survey.surveyStrings.savingDataError = "We had trouble saving your entries";
    // Survey.surveyStrings.savingDataSuccess =
    //   "We saved your information sucessfully - Thank you !";
    Survey.surveyStrings.saveAgainButton = "Try to save again.";

    // Display a preview before proceeding to a payment screen
    //survey.showPreviewBeforeComplete = "showAnsweredQuestions";

    //Survey.FunctionFactory.Instance.register("checkVoucher", checkVoucher);

    survey.onComplete.add(function(survey) {
        localStorage.setItem("Result", JSON.stringify(survey.data));
        //convert.json_to_xls(survey.data, "results.xls") ;
        // Call Axios function to do the conversion
        data.push(survey.data)

        // Experiemnting with vue-js-excel to export to excel
        //VueJsExcel(data,"testing 1 2 3", columns)

      
      survey.onSendResult.add(function(survey, opts) {
        if (opts.success) {
          console.log('Survey', survey.data);
          if (confirm("Do you want a PDF copy?")) {

            var options = {
              fontSize: 12,
              commercial: true, 
              margins: {
                  left: 10,
                  right: 10,
                  top: 10,
                  bot: 10
              },
              format: [210, 297]
            };
            var surveyPDF = new SurveyPDF.SurveyPDF(survey.toJSON(), options);
            surveyPDF.mode = "display"
            surveyPDF.data = survey.data;
            surveyPDF.save("result.pdf");
          }
        }
        
      });
    });

    return {
      survey: survey,
    };
  },
  methods: {},
};
</script>

<style scoped>

.Header {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 15px;
  color: grey;
}


.pay-button {
  text-align: center;
  background-color: green;
  color: white;
  font-size: 15px;
  margin-top: 25px;
}
</style>
